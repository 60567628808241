<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">live session</div>
      </v-row>
      <v-row class="">
        <div class="">
          Please note that listed session times may occasionally be delayed
          without notice. Also, broadcasts are often temporarily suspended for
          recess, commitee meetings, conferences or other legistlative
          activities.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <v-card>
        <v-card-text>
          <v-row
            class="font-weight-bold text-uppercase d-flex flex-column flex-md-row"
          >
            <v-col>
              <p class="text-uppercase black--text">meeting body</p>
              <div v-if="!session">No session ongoing</div>
              <div class="d-flex" v-if="session">
                <v-icon left color="black"> mdi-account-multiple </v-icon>
                <p class="my-auto">
                  {{ session.meeting_body }}
                </p>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col v-if="session && session.meeting_body !== 'house'">
              <p class="text-uppercase primary--text">committee</p>
              <div v-if="!session">No session ongoing</div>
              <div class="d-flex" v-if="session">
                <v-icon left color="primary"> mdi-account-group </v-icon>
                <p class="my-auto">
                  {{ session.committee.name || "no ongoing sessions" }}
                </p>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <p class="green--text">about session</p>
              <div v-if="!session">No session ongoing</div>
              <div class="d-flex" v-if="session">
                <v-icon left color="green"> mdi-script-text </v-icon>
                <p class="my-auto">
                  {{ session.name || "no ongoing sessions" }}
                </p>
                <!-- <v-btn
                  class="ml-2"
                  text
                  color="primary"
                  outlined
                  small
                  rounded
                  @click="pdfDialog = true"
                >
                  <v-icon>mdi-file-pdf-box</v-icon> intro document</v-btn
                > -->
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col v-if="session != null">
              <p class="purple--text">time</p>
              <div v-if="!session">No session ongoing</div>
              <div class="d-flex">
                <v-icon left color="purple"> mdi-clock-outline </v-icon>
                <p class="my-auto">
                  {{ `${session.date} ${session.time}` | moment("calendar") }}
                </p>
              </div>
            </v-col>
            <!-- <v-divider vertical></v-divider>
            <v-col>
              <p class="purple--text">ends at:</p>
              <div class="d-flex">
                <v-icon left> mdi-clock-outline </v-icon>
                <p class="my-auto">10:30 AM</p>
              </div>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          <p class="text-capitalize">Online meetings and voting</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="flex-column flex-sm-row">
            <v-col>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="meetingDialog = true"
                    v-if="user.role.name == 'Admin'"
                  >
                    <v-icon left>mdi-plus</v-icon> new meeting</v-btn
                  >
                  <v-divider
                    class="mt-2"
                    v-if="user.role.name == 'Admin'"
                  ></v-divider>
                  <v-card height="5rem" flat v-if="!meetings.length > 0">
                    <v-layout align-center justify-center fill-height>
                      <p class="text-subtitle-1">
                        There are no meetings to display
                      </p>
                    </v-layout>
                  </v-card>
                  <v-list two-line>
                    <v-list-item link v-for="item in meetings" :key="item.id">
                      <v-list-item-avatar>
                        <v-icon color="primary"> mdi-calendar </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.subject }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ `${item.date} ${item.time}` | moment("calendar") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn color="primary" :href="item.link" target="_blank"
                          >join</v-btn
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col
              class="d-flex flex-column align-center justify-center"
              cols="2"
            >
              <v-btn
                color="primary"
                fab
                small
                @click="record(), (doneTalking = false)"
              >
                <v-icon> mdi-microphone </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                class="mt-2"
                fab
                small
                @click="doneTalking = true"
              >
                <v-icon> mdi-stop </v-icon>
              </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="">
              <v-textarea
                label="Tap the microphone to start recording..."
                readonly
                v-model="runtimeTranscription_"
              ></v-textarea>
            </v-col> -->
            <v-divider vertical></v-divider>
            <v-col>
              <v-form>
                <p class="text-h6 text-uppercase">voting</p>
                <v-autocomplete
                  label="Vote Type"
                  :items="['Aye', 'Nay', 'Abstain']"
                  :disabled="toVoteItem == null || toVoteItem == undefined"
                  :rules="[rules.required]"
                  outlined
                  v-model="selection"
                ></v-autocomplete>

                <v-btn
                  color="primary"
                  @click="submitVote"
                  :disabled="toVoteItem == null || toVoteItem == undefined"
                  :loading="votingLoader"
                  >submit vote</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- presenter tools -->
      <v-card class="mt-2">
        <v-card-title>Presenter Tools</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card width="200" elevation="5" color="success" class="mx-auto">
                <v-card-title>
                  <p class="mx-auto my-auto display-1 white--text">05: 00</p>
                </v-card-title>
              </v-card>
              <div class="d-flex mt-3 justify-center">
                <v-btn color="primary"
                  >speak <v-icon right>mdi-hand-front-left</v-icon></v-btn
                >
                <v-btn color="error" disabled class="ml-4"
                  >cancel <v-icon right>mdi-hand-back-left-off</v-icon></v-btn
                >
              </div>
              <v-divider class="mt-2"></v-divider>
              <v-list two-line>
                <v-subheader
                  class="black--text font-weight-medium text-subtitle-1"
                  >Current Sessions Stats :</v-subheader
                >
                <v-list-item class="black--text font-weight-medium">
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Status </v-list-item-title>
                    <v-list-item-subtitle>Not raised hand</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="black--text font-weight-medium">
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> Times Spoken </v-list-item-title>
                    <v-list-item-subtitle>0 Times</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="">
              <v-card flat>
                <v-card-title>
                  <p>Your Sessions Stats</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-list two-line>
                    <v-list-item class="black--text font-weight-medium">
                      <v-list-item-icon>
                        <v-icon>mdi-circle-small</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Attendance</v-list-item-title>
                        <v-list-item-subtitle> 90 % </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="black--text font-weight-medium mt-n3">
                      <v-list-item-icon>
                        <v-icon>mdi-circle-small</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Contribution rate</v-list-item-title>
                        <v-list-item-subtitle>
                          4 Times/ Session
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3 text-capitalize">
        <v-card-title>
          <p>documents pertaining this session</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list v-if="session != null || session != undefined">
            <v-list-item
              v-for="item in session.files"
              :key="item.id"
              link
              @click="(pdfDialog = true), (pdf = item.location)"
            >
              <v-list-item-icon>
                <v-icon> mdi-file-pdf-box </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ session.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="black--text" v-else>No sessions ongoing</div>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="pdfDialog">
      <pdf-viewer :pdf="pdf" />
    </v-dialog>
    <v-dialog v-model="meetingDialog" width="auto">
      <v-card width="500">
        <v-card-title>
          <p class="mx-auto text-uppercase">create a meeting</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="meetingForm" ref="meetingForm">
            <v-text-field
              outlined
              dense
              :rules="[rules.required]"
              label="Meeting subject"
              v-model="subject"
              prepend-icon="mdi-text"
            />
            <v-text-field
              outlined
              dense
              :rules="[rules.required]"
              label="Paste meeting link"
              v-model="link"
              prepend-icon="mdi-link"
              append-icon="mdi-clipboard-outline"
              @click:append="pasteLink()"
            />
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  :rules="[rules.required]"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
            <!-- time -->
            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  label="Select time"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="[rules.required]"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                format="24hr"
                full-width
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
            <v-btn
              color="primary"
              block
              :loading="meetingLoader"
              :disabled="!meetingForm"
              @click="createMeeting"
              >create meeting</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-center text-capitalize">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import PdfViewer from "../components/PdfViewer.vue";
import { mapActions, mapState } from "pinia";
import { sessionStore } from "../stores/sessions";
import { userStore } from "../stores/user";
import Api from "@/services/Api";

export default {
  components: { PdfViewer },
  data() {
    return {
      pdfDialog: false,
      runtimeTranscription_: "",
      transcription_: [],
      lang_: "en-US",
      doneTalking: false,
      meetingDialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      time: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(11, 5),
      subject: "",
      meetingForm: false,
      timeMenu: false,
      dateMenu: false,
      selection: false,
      link: "",
      rules: {
        required: (v) => !!v || "Required",
      },
      meetingLoader: false,
      votingLoader: false,
      meetings: [],
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      pdf: "",
      toVoteItem: null,
    };
  },
  computed: {
    ...mapState(sessionStore, ["session"]),
    ...mapState(userStore, ["user"]),
  },
  methods: {
    ...mapActions(sessionStore, ["getSessions"]),
    submitVote() {
      this.votingLoader = true;

      let member_vote;
      if (this.selection == "Abstain") member_vote = null;
      else if (this.selection == "Aye") member_vote = 1;
      else member_vote = 0;

      Api.createVote({
        member_vote: member_vote,
        member_id: this.user.id,
        item_id: this.toVoteItem.voting_record.id,
      })
        .then(() => {
          this.showSnackBar(true, "Vote registered succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.votingLoader = false;
        });
    },
    createMeeting() {
      this.meetingLoader = true;
      Api.createMeeting({
        time: this.time,
        date: this.date,
        subject: this.subject,
        link: this.link,
      })
        .then(() => {
          this.showSnackBar(true, "Meeting created succesfully");
          this.meetingDialog = false;
          this.getMeetings();
          this.$refs.meetingForm.reset();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.meetingLoader = false;
        });
    },
    pasteLink() {
      navigator.clipboard
        .readText()
        .then((text) => {
          this.link = text;
        })
        .catch(() => {
          this.showSnackBar(false, "Failed to read copied text");
        });
    },
    getMeetings() {
      Api.getMeetings().then((res) => {
        this.meetings = res.data.success.data;
      });
    },
    record() {
      window.SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new window.SpeechRecognition();
      recognition.lang = this.lang_;
      // recognition.interimResults = true;
      // recognition.continuous = true;
      recognition.addEventListener("result", (event) => {
        var text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        this.runtimeTranscription_ = this.runtimeTranscription_ + " " + text;
      });

      recognition.start();

      recognition.addEventListener("end", () => {
        if (this.doneTalking) {
          this.transcription_.push(this.runtimeTranscription_);
          this.runtimeTranscription_ = "";
          recognition.stop();
        } else {
          recognition.start();
        }
      });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  created() {
    this.getSessions();
    this.getMeetings();
    Api.getVoteItems().then((res) => {
      this.toVoteItem = res.data.data.find(
        (item) => item.voting_record.voting_status == 1
      );
    });
  },
};
</script>

<style></style>
